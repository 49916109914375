import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetOrdersQuery } from '../../../../utils/services/api/orderApi';
import { useSelector } from 'react-redux';
import { user } from '../../../../redux/selectors';
import Status from '../../../../components/tabler/Status';
// import { useReactToPrint } from 'react-to-print';
// import { InvoiceItem } from '../../../../components/invoice/InvoiceItem';

function DailySales(props) {
    const currentUser = useSelector(user)
    const [filter] = useState({
        query: '',
        userId: currentUser.id,
        date: props.date
    })
    const {data: orders, isLoading} = useGetOrdersQuery(filter);

    let mtt = 0;
    let balance = 0;
    let items = 0;

    if(!isLoading){
        for(let i of orders){
            mtt=mtt+((i.montantVendu + i.taxes) - i.discount);
            balance=balance+i.balance;
            items=items+i.items;
        }
    }

    // Printer
    // const componentRef = useRef();
    // const print = useReactToPrint({
    //     content: () => componentRef.current,
    // });
    // const [idToPrint, setIdToPrint] = useState(0);
    // const handlePrint = (id) =>{
    //     setIdToPrint(id)
    //     print()
    // }

    // const {data: order} = useGetOneOrderQuery(idToPrint);
    // const [carts, setCarts] = useState([]);

    // useEffect(()=> {
    //     if(typeof order?.cart !== 'undefined'){
    //         setCarts(order?.cart);
    //     }
    // },[order])

    // Pour exporter en format Excel
    
     props.setDailySales(orders);

  return (
    <div className="card">
        <div className="table-responsive">
            <table className="table table-vcenter card-table">
                <thead>
                <tr>
                    <th>No Facture</th>
                    <th>Client</th>
                    <th>Statut</th>
                    <th>Montant</th>
                    <th>Balance</th>
                    <th>Quantité</th>
                    {/* <th className="w-1"></th> */}
                </tr>
                </thead>
                <tbody>
                    {isLoading &&
                        <tr>
                            <td colSpan={6} style={{textAlign: 'center'}}>Loading<span class="animated-dots"></span></td>
                        </tr>
                    }
                    {orders?.map((order, i)=> (
                        <tr key={i}>
                            <td ><Link to="#" className="text-reset">{order.noFacture}</Link></td>
                            <td className="text-muted">{order.fullnameClient}</td>
                            <td className="text-muted">
                                <Status color={order.statut==='paid' ? 'green' : 
                                (order.statut==='due' ? 'red' : 'yellow')}>
                                    {order.statut==='paid' ? 'Payé' : 
                                (order.statut==='due' ? 'Dette' : 'Balance')}
                                </Status>
                            </td>
                            <td className="text-muted" >{(order.montantVendu + order.taxes) - order.discount}</td>
                            <td className="text-muted" >{order.balance}</td>
                            <td className="text-muted" >{order.items}x</td>
                            {/* <td>
                                <button className="btn btn-icon btn-ghost-secondary" onClick={()=> handlePrint(order.id)} data-bs-toggle="offcanvas" href="#offcanvasEnd" aria-controls="offcanvasEnd">
                                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-printer"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" /><path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" /><path d="M7 13m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z" /></svg>
                                </button>
                            </td> */}
                        </tr>
                    ))}

                    {orders?.length > 0 && !isLoading &&
                        <tr>
                            <td colSpan={3}>Total:</td>
                            <td className="text-muted">{mtt}</td>
                            <td className="text-muted">{balance}</td>
                            <td className="text-muted" colSpan={2}>{items}x</td>
                        </tr>
                    }
                    {orders?.length === 0 &&
                        <tr>
                            <td colSpan={6} style={{textAlign: 'center'}}>Aucune donnée n'a été retournée</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>

        {/* <div style={{display: 'none'}}>
            <InvoiceItem ref={componentRef} list={carts}
                item={order?.items} totalSale={order?.montantVendu} discount={order?.discount}
                taxes={order?.taxes} salesPerson={order?.username} customer={`${order?.nomCustomer} - ${order?.telCustomer}`}
                montantDue={order?.montantDue} renduMonnaie={order?.monnaie} montantVerser={order?.montantVerser}
            />
        </div> */}

    </div>
  )
}

export default DailySales